/** 关于IPBS */
<template>
  <div class="content">
    <div class="aboutIPBS  back-fff">
      <!-- 知识产权证券化发行的基本概念 -->
      <section class="aboutIpbs-concept">
        <h2 class="IPBS-subtitle">知识产权证券化发行的基本概念</h2>
        <p class="IPBS-info concept-info">知识产权证券化（IP-Backed
          Securities,IPBS）是指将原始权益人拥有的基础资产（知识产权衍生的债权、收益权）打包移转至特殊目的机构（SPV），实现“真实出售”和“风险隔离”，以知识产权衍生债权或收益权未来产生的现金流为偿付支持，通过结构化设计进行信用增级，在此基础上发行资产支持证券的过程。
        </p>
      </section>
      <!-- 知识产权证券化发行的里程碑 -->
      <section class="aboutIpbs-video">
        <h2 class="IPBS-subtitle">知识产权证券化发行的里程碑</h2>
        <h4 class="aboutIpbs-text">动画解说</h4>
        <IPBSVideo />
      </section>
      <!-- 智慧财富总协调人机制 -->
      <section class="aboutIpbs-mechanism ">
        <h2 class="IPBS-subtitle">智慧财富总协调人机制</h2>
        <el-image class="mechanism-img" :src="require('@/assets/images/systematic-banner.png')" fit="contain">
        </el-image>
      </section>
    </div>
    <!-- 合作咨询电话 -->
    <section class="aboutIpbs-phone">
      <p class="aboutIpbs-phone-text">合作咨询电话</p>
      <p class="aboutIpbs-phone-num">188 - 0102 - 2899</p>
    </section>
  </div>
</template>
<script>
import IPBSVideo from '@/components/Video.vue'
export default {
  name: 'AboutIPBS',
  components: {
    IPBSVideo
  }
}
</script>
<style lang="scss" scoped>
.aboutIPBS {
  padding: 70px 100px 10px;
  section {
    margin-bottom: 90px;
  }
}
// 副标题
.aboutIpbs-text {
  text-align: center;
  font-size: 20px;
  margin: 37px 0 30px;
}

.concept-info {
  font-size: 18px;
  margin-top: 50px;
}

// 视频
/deep/ .video-js {
  width: 100%;
  max-width: 830px;
  height: 469px;
  margin: 0 auto;
  padding: 0;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
}

// 机制
.mechanism-img {
  margin-top: 50px;
}

// 联系电话
.aboutIpbs-phone {
  margin-top: 57px;
  p {
    text-align: center;
    font-weight: bold;
  }
  .aboutIpbs-phone-text {
    color: #666666;
  }
  .aboutIpbs-phone-num {
    font-size: 30px;
    margin-top: 26px;
  }
}
@media (max-width: 767px) {
  .aboutIPBS {
    padding: 10px;
  }
}
</style>